/* -------------------------------------------------------------------------- */
/*                             Coveo Search Modal                             */
/* -------------------------------------------------------------------------- */

#coveomodal1 {
    font-family: $font-family-base;


    &.fade.in {
        padding: 0 !important;
    }

    .modal-backdrop {
        background-color: #333!important;
        right: 0;
    }

    .modal-dialog {
        margin: 0;
        width: 100%;
        max-width: none;
        position: relative;
        top: inherit;

        .modal-content {

            position: relative;
            -webkit-box-orient: vertical;
            -webkit-box-direction: normal;
            -ms-flex-direction: column;
            flex-direction: column;
            width: 100%;
            pointer-events: auto;
            background-color: #fff;
            background-clip: padding-box;
            border: 1px solid rgba(0,0,0,.2);
            border-radius: 2px;
            outline: 0;

            padding: 114px 0 61px;

            @include media-breakpoint-up(md) {
                padding: 130px 0 70px;
            }

            border: none;
            border-radius: none;
            -webkit-box-shadow: 0 15px 30px 0 rgba(0,0,0,.2);
            box-shadow: 0 15px 30px 0 rgba(0,0,0,.2);




            .modal-header {
                display: flex;
                align-items: flex-start;
                justify-content: space-between;
                padding: 16px;
                border-bottom: 1px solid #e9ecef;
                // border-top-left-radius: 2px;
                // border-top-right-radius: 2px;
                background-color: transparent;
                border: none;
                padding: 0;
                min-height: 0;

                &::before, &::after {
                    content: none;
                }

                .modal-title {

                }
                .close{
                    @extend .btn-dark;

                    position: absolute;
                    top: 20px;
                    right: 20px;
                    margin: 0;
                    opacity: 1;
                    font-size: 30px;
                    font-weight: 300;
                    padding: 0;
                    border-radius: 50%;
                    line-height: 1;
                    width: 50px;
                    height: 50px;
                    z-index: 1;
                    text-indent: 0;
                    text-shadow: 0 1px 0 #fff;
                    -webkit-font-smoothing: antialiased;

                    &:hover {
                        transform: inherit;
                    }

                }
            }
            .modal-body {
                display: block;
                flex: 1 1 auto;
                padding: 16px;
                width: 1170px;
                max-width: 90%;
                margin: 0 auto;
                position: static;

                @include media-breakpoint-up(md) {
                    display: flex;
                }

                .global-search-section__search-text {
                    margin-right: 8px;
                    vertical-align: top;
                    line-height: 50px;
                    display: inline-block !important;
                    color: $gray-900;
                    font-weight: 400;
                    font-size: 16px;
                }

                .global-search-section__coveo-block {
                    width: 90%;
                    margin-bottom: 7px;
                }
            }

        }
    }
}

#coveomodal1 {

/* ------------------------------ Search Button ----------------------------- */
.CoveoSearchButton {
    background-color: $red;
    border: 0 !important;
    border-radius: 0 4px 4px 0;
    color: $white;
    height: 50px;
    line-height: 0;
    overflow: hidden;
    text-align: center;
    text-decoration: none;
    top: 0;
    vertical-align: middle;
    width: 60px;

    &:hover {
        opacity: .9;

        .coveo-magnifier-circle-svg {
            fill: $white;
        }
    }
}

.coveo-search-button-svg {
    color: $white;
    fill: $white;
}

.coveo-search-section {
    width: 100%;
    max-width: inherit;
    padding: 0;
}

/* -------------------------------- Magic Box ------------------------------- */
.magic-box {
    width: 100%;
    margin-top: 0;

    .magic-box-underlay {
        top: 0;
    }

    .magic-box-input {
        height: 48px;
    }

    .magic-box-input > input {
        color: #67768b !important;
        text-align: left;
        line-height: 24px;
        position: relative;
        top: auto;
        left: auto;
        height: 48px;
        white-space: pre;
        overflow: auto;
        box-sizing: border-box;
        display: block;
        background-color: $gray-100;
        font-size: 30px;
        padding: 14px 22.4px;
        font-weight: 500;
        border-radius: 0;
        width: 100%;



    }

    .magic-box-input > input::placeholder {
        color: #67768b !important;
        font-weight: 500;
    }
}


}