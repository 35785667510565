.l-footer {
    this: &;

    background-color: #e9ecef;
    font-size: 12px;
    line-height: 1.5;
    display: block;

    a {
        color: #000;
        font-size: 12px;
        line-height: 21px;
    }

    #contact-collapse-footer > * {
        line-height: 21px;
    }

    p {
        @include media-breakpoint-down(sm) {
            font-size: 12px;
        }
    }

    &__compliance {
        display: none;



        @include media-breakpoint-up(md) {
            display: flex;
            max-width: 400px;
            align-items: center;
            margin: 0 auto;
        }

        a {
            font-size: 14px;
            text-decoration: underline;
        }

        img {
            margin-right: 20px;
        }

        p {
            line-height: inherit;
            margin: inherit;
            font-size: 14px;
        }


    }

    &__copyright {
        text-align: center;
        

        p {
            line-height: 18.375px !important;
            font-size: 12.25px !important;
            
            @include media-breakpoint-up(md) {
                line-height: 21px !important;
                font-size: 14px !important;
            }
        }

        @include media-breakpoint-up(md) {
            margin-bottom: 25px;
        }
    }

    

    &__logos {
        display: none;

        @include media-breakpoint-up(md) {
            display: flex;
        }
    }

    &__nav {
        border-bottom: 1px solid $gray-border-alt;

        @include media-breakpoint-up(md) {
            border: none;
        }
    }

    &__nav a {
        display: block;
        padding-top: 3px !important;
        padding-bottom: 3px !important;
    }

    &__nav-title {
        display: none;

        @include media-breakpoint-up(md) {
            display: inherit;
            margin-bottom: 20px;
        }
        
    }

    &__section {
        padding: 26.25px 0;
        border-bottom: 1px solid $gray-border;

        @include media-breakpoint-up(md) {
            padding: 45px 0;
        }
    }

    &__social {
        display: flex;
        align-items: center;
        justify-content: center;
        
        @include media-breakpoint-up(md) {
            justify-content: space-between;
            
        }

        li a {
            margin: 7px 0 7px 7px;
            line-height: 18.375px;
            font-size: 12.25px;
        }
    }

    &__supplemental-nav {
        margin-bottom: 25px;

        li a {
            font-size: 12.25px !important;
            line-height: 18.375px !important;

            @include media-breakpoint-up(md) {
                font-size: 14px !important;
                line-height: 21px !important;
            }
        }
    }



    .c-inquiries-support {
        background-color: #e8f5f3;
        padding-top: 25px;
        padding-bottom: 25px;

        @include media-breakpoint-down(sm) {
            &__content, &__cta {
                margin-bottom: 21.875px;
                font-size: 12.25px;
                line-height: 18.375px;
            }
     
            &__cta:last-child {
                margin-bottom: 0;
            } 
        }

        
    }

    .c-cta {
        font-weight: 600;
        position: relative;

        /*&.h-theme--black {
            color: #343a40!important
        }*/
    }

    .c-cta--arrow {
        padding-right: 30px;
        padding-left: 0 !important;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    // .c-cta--arrow::after {
    //     font-family: aha-icon-font!important;
    //     speak: none;
    //     font-style: normal;
    //     font-weight: 400;
    //     font-variant: normal;
    //     text-transform: none;
    //     line-height: 1;
    //     -webkit-font-smoothing: antialiased;
    // }

    // .c-cta--arrow::after {
    //     content: "\E905";
    //     transform: rotate(270deg);
    //     position: absolute;
    //     font-size: 9px;
    //     top: 20%;
    //     right: 0;
    //     margin-top: 4px;
        
    // }
    .icon-down-arrow {
        width: 10px;
        fill: currentColor;
        margin-left: 4px;
        transform: rotate(270deg);
        transition: all .2s linear;
    }



    .l-divider-link-nav {
        justify-content: center;

        &:first-child {
            padding-left: 0;
        }

        li {
            padding-right: 5px;
            position: relative;
            padding-left: 8px;

            a {
                padding: 0;
                font-size: 14px;
                line-height: 21px;
            }
        }

        li:not(:first-child)::after {
            content: "|";
            display: block;
            position: absolute;
            top: -1px;
            left: 0; 
        }
    }

    .l-icon-nav {
        display: inline-flex;
        align-items: center;
        justify-content: center;

        

        li {
            margin: 0 4px;
        }

        li:first-child {
            margin-left: 0;
        }

        a {
            padding: 0;
        }
    }

    

    .l-footer__logo {
        margin-bottom: 20px;
        text-align: center;
        
        @include media-breakpoint-up(md) {
            text-align: left;
        }

        img {
            max-width: 160px;
            height: auto;
            vertical-align: middle;
            border-style: none;

            @include media-breakpoint-down(sm) {
                max-width: 50%;
            }
        }
    }

    .btn--select {
        display: flex;
        justify-content: space-between;
        width: 100%;
        text-align: left;
        margin-left: 0;
        margin-right: 0;
        border: none;
        padding-top: 22px;
        padding-bottom: 22px;
        padding-left: 0;
        margin-bottom: 0;
        margin-top: 0;
        padding-right: 0;
        font-size: 14px;
        line-height: 21px;
        text-transform: none;
        

        @include media-breakpoint-up(md) {
            display: none;
        }
    }

    // .btn--select::after {
    //     font-family: aha-icon-font!important;
    //     speak: none;
    //     font-style: normal;
    //     font-weight: 400;
    //     font-variant: normal;
    //     text-transform: none;
    //     line-height: 1;
    //     -webkit-font-smoothing: antialiased;
    //     content: "\E905";
    //     right: 20px;
    //     border: none;
    //     position: absolute;
    //     font-size: 10.5px;
    //     top: auto;
    //     margin-top: 0;
    //     padding: 0;
    //     width: auto;
    //     height: auto;
    //     vertical-align: middle;
    //     transition: all .2s linear;
    //     transform: rotate(180deg);
    // }

    .btn--select .icon-down-arrow {
        transform: rotate(180deg);
    }

    .btn--select.collapsed .icon-down-arrow{
        transform: rotate(0deg);
        will-change: transform;
        transition: all .2s linear;
    }

    .collapse {
        display: none;
        padding-bottom: 14px;

        @include media-breakpoint-up(md) {
            display: block !important;
            padding-bottom: 0;
            visibility: visible;
        }
    }

    .collapse.show {
        visibility: visible;
    }

    .nav {
        display: flex;
        flex-wrap: wrap;
        padding-left: 0;
        list-style: none;

        li.nav-item {
            a:hover {
                text-decoration: underline;
            }
        }

    }

}

/* OneTrust Cookie Link */
footer .l-footer__supplemental-nav a.ot-sdk-settings-link {
	color: black !important;
	border: 0px !important;
	padding: 0px !important;
}

footer .l-footer__supplemental-nav a.ot-sdk-settings-link:hover {
	background-color: transparent !important;
	text-decoration: underline !important;
}
