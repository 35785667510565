.l-header .btn, .l-footer .btn {
    -webkit-box-shadow: none;
    box-shadow: none;
    border: 1px solid transparent;
    display: inline-block;
    font-size: 16px;
    font-weight: 500;
    letter-spacing: initial;
    line-height: 1.5;
    margin: initial;
    padding: 8px 34px;
    text-align: center;
    vertical-align: middle;
    white-space: nowrap;

    &-round {
        border-radius: 10em;
    }

    &-light {
        color: #222328;
        background-color: #f8f9fa;
        border-color: #f8f9fa;
    }

    &-light:hover {
        color: #222328;
        background-color: #e2e6ea;
        border-color: #dae0e5;
    }

    &-light:focus {
        @include button-focus-outline;
    }

    &-dark {
        color: #fff;

        @include media-breakpoint-up(md) {
            background-color: #343a40;
            border-color: #343a40; 
        }
        

        &:hover {
            color: #fff;
            background-color: #23272b;
            border-color: #1d2124;

            
        }

        &:focus {
            color: #fff;
            box-shadow: 0 0 0 4px rgba(52,58,64,.5);
        }
    }

    &.btn-link-plain {
        color: inherit;
        background: transparent;
        font-weight: inherit;
    }

    &--select {
        text-transform: none;
    }

}