// Use media queries like this:
// @include media-query($on-palm) {
//   .wrapper {
//     padding-right: $spacing-unit / 2;
//     padding-left: $spacing-unit / 2;
//   }
// }
//@include media-breakpoint-up(md) {
//
//}


@mixin media-query($device) {
    @media screen and (max-width: $device) {
      @content;
    }
  }
  
  @mixin relative-font-size($ratio) {
    font-size: $base-font-size * $ratio;
  }

  @mixin font-base() {
    font-family: $font-family-base;
    font-size: $font-size-base;
    line-height: $line-height-base;
  }

  @mixin button-focus-outline() {
    box-shadow: 0 5px 10px 0 rgba(0,0,0,.2),0 0 0 4px rgba(248,249,250,.5)!important;
  }