

.c-nav-dropdown {
    @include font-base;
    margin: 18px 0 0 0;
    padding: 30px;
    min-width: 140px;
    will-change: top, left;
    box-shadow: none;
    border-radius: 0;

    &__container {
        $this: this;

        // position: relative;

        #{$this}.show {

        }
    }

    &__list {
        margin-bottom: 0;
        margin-top: 0;
    }

    &__item {
        margin-top: 10px;
    }

    &__header {
        font-weight: 600 !important;
    }

    &__link {
        color: $gray-700 !important; 
        font-size: 16px;
        padding: 0 !important;
        line-height: 24px !important;

        &:hover {
            background-color: transparent !important;
            text-decoration: underline !important;
        }
        

    }

    &__arrow {
        position: relative;
        top: -20px;
        left: 61px;
        position: absolute;
        width: 20px;
        height: 20px;

        &::before {
            height: 0;
            width: 0;
            border: 11px solid transparent;
            border-bottom-color: #e3e3e3;
            margin-left: -1px;
            margin-top: -2px;
            content: "";
            position: absolute;
            top: 0;
            left: 0;
        }

        &::after {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            height: 0;
            width: 0;
            border: 10px solid transparent;
            border-bottom-color: #fff;
        }
    }

    &.dropdown-menu {
        $this: this;

        left: inherit;
        
        &.is-shown {
            visibility: hidden;
            display: block;
        }
            
        &.is-visible {
            visibility: visible;
        }
    
        ul {
            list-style-type: none;
            padding: 0;
    
            li:first-child {
                margin-top: 0;
            }
        }
    
        
    
    }
}



/* -------------------------------- Sitecore -------------------------------- */

.c-mega-nav {
    position: static;

    a {
        line-height: 24px !important;
    }

    a:hover {
        text-decoration: underline !important;
    }

    ul, ol {
        list-style-type: none;
    }

    &__container {
        & > li {
            margin-top: 20px;

            &:first-child {
                margin-top: 0;
            }

            &:last-child {
                & > ul {
                    margin-bottom: 0;
                }
            }
        }
    }

    &__links {
        padding: 0;
        margin-bottom: 40px;

        & > li {
            flex: 0 0 25%;
            margin: 10px 0 0 0;
        }
    }

    // Used if we have over 4 items in a list
    &__links--columns {
        columns: 150px 4;
        display: flex;
        flex-wrap: wrap;

        //Webkit has a bug with margin-top on the li's - also added right padding in case text bump against each other
        li {
            margin: 0;

            a {
                margin: 10px 10px 0 0;
                white-space: normal;
            }
        }
    }

    &__header, &__link {
        display: inline-block;
        padding: 0 !important;
        width: auto;
        white-space: normal;

        &:hover, &:focus, &:active {
            background: transparent !important;
        }
    }

    &__header {
        font-weight: 600 !important;
    }

    .dropdown-menu {
        margin-top: 30px;
        top: auto !important;
        right: 0;
        border: none;
        border-top: 1px solid $gray-border;
        box-shadow: $box-shadow;
        padding: 35px 0;
        left: 0;
    }

    .nav-link {
        &[aria-expanded=true] {
            color: $red;
            // @include bordered-triangle(20px, 1px, $white, $gray-border, up, true);

            &:after,
            &:before {
                top: auto;
                bottom: -31px;
                z-index: 1002;
                left: calc(50% - 10px);
            }
        }
    }
}
