
$white:    #fff !default;
$gray-100: #f8f9fa !default;
$gray-200: #e9ecef !default;
$gray-300: #dee2e6 !default;
$gray-400: #c4cacf !default;
$gray-500: #979797 !default;
$gray-600: #6D6E71 !default;
$gray-700: #495057 !default;
$gray-800: #343a40 !default;
$gray-900: #222328 !default;
$black:    #000 !default;

$highlight-blue: #009CE0 !default;
$blue:    #334CD5 !default;
$red:     #C10E21 !default;
$orange:  #FE5339 !default;
$yellow-orange:  #F8B416 !default;
$green:   #468438 !default;
$aqua:    #51DDE9 !default;

$dark-gray: #6D6E71;
$dark-gray-alt: #979797;
$red-alt: #9d0b1b;
$black-alt: #222328;
$blue-alt: #3B55E6;
$aqua-alt: #32CDDA;
$green-alt: #426A3A;
$orange-alt: #EB4E36;
$yellow-orange-alt: #E28413;

$green-light: #4bc2ac;
$green-lime: #b8e885;

$gray-border: #E3E3E3;
$gray-border-alt: #CACACA;

/* -------------------------------- Map merge ------------------------------- */
$themecolors: () !default;
$themecolors: map-merge((
    "white": $white,
    "red": $red,
    "black": $black,
    "blue": $blue
), $themecolors);

@mixin h-colors {
    @each $name, $hex in $themecolors {
        &--#{$name} {
            color: $hex !important;
        }
    }
} // @mixin bg-colors


.h-color {
    // This creates .theme-bg--color-name classes for everything in the $themecolors map.  example: .theme-bg--red
    @include h-colors;
} // .theme-bg

.h-theme {
    // This creates .theme-bg--color-name classes for everything in the $themecolors map.  example: .theme-bg--red
    @include h-colors;
} 