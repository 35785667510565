.l-header {
    .l-header__main {
        display: none;
        padding: 30px 0;
        border-bottom: 1px solid $gray-border;

        @include media-breakpoint-up(md) {
            display: block;
        }

        .c-main-nav {
            padding: 0;
            margin: 0 -5px;
            display: none;

            @include media-breakpoint-up(md) {
                display: flex;
            }

            ul {
                list-style-type: none;
                padding: 0;
                margin: 0; 
            }

            &>* {
                display: flex;
                -webkit-box-orient: horizontal;
                -webkit-box-direction: normal;
                -ms-flex-direction: row;
                flex-direction: row;
            }

            &__item {
                margin: 0 5px;
            }

            &__item:first-child {
                margin-left: 0;
            }

            .c-mega-nav {
                position: static;

                &__item {
                    margin-right: 5px;
                }
            }

            .c-nav-dropdown__container.show .c-top-nav__link {
                color: $red;
            }
        } //.c-main-nav
    }
}