button > .c-underline-link {
    display: inline-block;

    &::after {
        display: block;
        visibility: hidden;
        content: "";
        border-bottom: 1.75px solid #c10e21;
        -webkit-transform: translateY(8px);
        transform: translateY(8px);
        opacity: 0;
        -webkit-transition: all .3s cubic-bezier(.76,.08,.18,.99);
        transition: all .3s cubic-bezier(.76,.08,.18,.99);
        position: absolute;
        left: 0;
        right: 0;
        -webkit-backface-visibility: hidden;
        backface-visibility: hidden;
        will-change: transform,opacity;
    }

    &:focus,&:hover {
        &::after {
            visibility: visible;
            transform: translateY(2px);
            opacity: 1; 
        }
    }
}

.c-nav-dropdown__container.show>.c-cta>.c-underline-link::after {
    visibility: visible;
    transform: translateY(2px);
    opacity: 1;
}

button:focus {
    .c-underline-link {
        &::after {
            visibility: visible;
            transform: translateY(2px);
            opacity: 1;
        }
    }
}