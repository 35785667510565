body.modal-open {
    /* preventing the page from shifting when modal is opened*/
    overflow: auto !important;
    padding-right: 0 !important;
}
@include media-breakpoint-down(sm) {
    body.modal-open--mobile-nav {
        // position: fixed;
    }
}

body .l-header a {
    color: #495057;
}
body .l-footer a {
    color: $black;
}


.l-header, .l-footer {
    font-family: $font-family-sans-serif;
    font-weight: $font-weight-normal;
    font-size: $font-size-base;
    line-height: $line-height-base;
    -webkit-font-smoothing: auto;
    color: $black;
    

    a {
        text-decoration: none;
        white-space: normal;
    }

    a:hover {
        text-decoration: underline;
    }

    dl, ol, ul {
        margin-top: 0;
    }

    b, strong {
        font-weight: 600;
    }

   :focus {
       outline: -webkit-focus-ring-color auto 1px !important; 
   }

    .h6 {
        font-size: 16px;
        font-family: inherit;
        font-weight: 500;
        line-height: 1.2;
        color: inherit;
        margin-top: 0;

        @include media-breakpoint-down(sm) {
            font-size: 14px;
        }
    }

    .nav::after, .nav::before {
        content: none;
    }

    .sr-only {
        position: absolute;
        width: 1px;
        height: 1px;
        padding: 0;
        overflow: hidden;
        clip: rect(0,0,0,0);
        white-space: nowrap;
        -webkit-clip-path: inset(50%);
        clip-path: inset(50%);
        border: 0;
    }

    .container-fluid {
        width: 100%;
        padding-right: 15px;
        padding-left: 15px;
        margin-right: auto;
        margin-left: auto;
    }

    .container-wide {
        max-width: 1320px;
        margin-left: auto;
        margin-right: auto;
    }

    .container::before, .container::after {
        content: none;
    }
    


    .mr-auto {

        margin-right: auto!important;
    }
}

/*::selection {
    background-color: transparent !important;
    color: currentColor !important;
}*/

@import 
    'ecards/bootstrap',
    'ecards/cta',
    'ecards/buttons',
    'ecards/dropdown-toggle',
    'ecards/alert',
    'ecards/underline-link',
    'ecards/header-top',
    'ecards/header-main',
    'ecards/header-utility',
    'ecards/mobile-nav',
    'ecards/dropdown-menu',
    'ecards/coveo-search-modal',
    'ecards/footer',
    'ecards/twoclick-modal'
;