.l-header {
    @include media-breakpoint-down(sm) {
        &__top ~ &__utility {
            transition: all .35s ease;
            transition-delay: .2s;
            background-color: $white;
        }
    }
    

    &__utility {
        padding: 15px 0;
        border-bottom: 1px solid $gray-border;

        @include media-breakpoint-up(md) {
            display: none;
        }
    }

    .c-utility-nav {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        margin: 0;

        li {
            margin-left: 8px;

        }

        li:not(:first-child)::before {
            content: "|";
            display: inline-block;
            margin: 0 8px; 
        }

        @include media-breakpoint-down(sm) {
            .c-cta--icon {
                display: initial !important;
            }
        }

        a {
            display: inline-block;
            padding: 0;
            font-size: 14px;
        }
    }
}