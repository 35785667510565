
.c-mobile-nav {
    @include media-breakpoint-up(md) {
        display: none;
    }
}

#mobile-navigation-wrapper {
    position: static;
}

#mobile-navigation {
    $this: &;
    font-family: $font-family-base;



    &.unmodal {
        top: 70px;
        bottom: 0px;
        padding: 0;
        left: 0;
        /* temp */
        position: absolute;
        width: 100%;
        z-index: 1000;
    }

    &.fade{
        opacity: 1 !important;

        .modal-dialog {
            transform: translate(-100%);
            margin: 0 -15px;
            transition: transform .3s ease-out,-webkit-transform .3s ease-out;
        }
    }

    &.fade.show {
        opacity: 1 !important;

        .modal-dialog {
            transform: translate(0);
            // margin: 0 -15px;
            // transition: transform .3s ease-out,-webkit-transform .3s ease-out;
        }
    }

    .modal-dialog {
        width: initial;
    }

    .modal-body {
        position: static;
        flex: 1 1 auto;
        padding: 0;
    }

    .modal-content {
        box-shadow: none;
        border: none;
        border-radius: 0;
    }

    .c-mobile-nav__search {
        display: flex;
        align-items: center;
        margin-top: 0;

        label {
            flex: 1;
            margin: 0 0 0 0;
            font-weight: 400 !important;
            width: 100%;
        }

        input {
            flex: 1;
            border: none;
            border-bottom: 1px solid $gray-border;
            padding: 6px 4px;
            overflow: visible;
            width: 100%;
            background: transparent !important;
            line-height: 21px;
            margin-bottom: 0;
        }

        img {
            width: 18px;
            height: auto;
        }

        &-submit {
            padding: 1px 6px;
        }
    }

    .c-mobile-nav__list {
    list-style-type: none;
    margin: 0;
    padding: 0; 
    border-bottom: 1px solid $gray-border;
    
    }

    .c-mobile-nav__list-item {
        color: $black;
        width: 100%;
        border: none;
        border-bottom: 1px solid $gray-border;
        padding: 16px;
        font-size: 14px;
        position: relative;

        &.btn {
            text-align: left;
            font-weight: 600;

        }

        &.btn--select {
            padding: 14px;
            margin: 0px !important;
            border-bottom: none;
            display: flex;
            justify-content: space-between;
            // &::after {
            //     font-family: aha-icon-font;
            //     content: "\E905";
            //     right: 30px;
            //     border: none;
            //     position: absolute;
            //     font-size: 10px;
            //     top: 35%;
            //     margin-top: -.375rem;
            //     padding: 0;
            //     width: auto;
            //     height: auto;
            //     vertical-align: middle; 
            //     transition: all .2s linear;
            //     transform: rotate(180deg);
            // }

            &.collapsed {
                

                .icon-down-arrow {
                    transform: rotate(0deg); 
                }

            }

            .icon-down-arrow {
                width: 10px;
                fill: currentColor;
                margin-left: 4px;
                transition: all .2s linear;
                transform: rotate(180deg);
                will-change: transform;
            }


        }


    }

    .c-mobile-nav__social {
        background-color: $gray-100;
        text-align: center;
        padding-top: 24px;
        padding-bottom: 10px;
    }

    .l-icon-nav {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        border: none !important;

        li {
            margin-left: 8px !important;
            margin-right: 8px !important;
        }
    }

    .c-mobile-nav__sub-list {
        position: relative;
        padding: 0 14px 14px;
        border-bottom: 1px solid $gray-border;

        &::before {
            content: none;
        }

        &:first-child {
            margin-left: 0;
        }

        li {
            padding: 4px 0;
            margin: 0px;

            a {
                padding: 0;
                font-size: 14px;
                font-weight: 400;
                line-height: 21px;
            }
        }

        
    }

    .collapse.show {
        visibility: visible;
    }
    
}

