#speed-bump {
    font-family: $font-family-base;
    color: $black;
    -webkit-font-smoothing: auto;

    .modal-backdrop.fade.in {
        padding-right: 0;
        right: 0;
        background-color: #000 !important;
    }

    .modal-dialog {
        width: 550px;

        .modal-content {
            border-radius: 0;
            text-align: center;
            box-shadow: none;

            .modal-header {
                display: none;
            }
            .modal-body {
                padding: 50px 30px 30px 30px;

                .close {
                    font-size: 40px;
                    font-weight: 300;
                    color: $black !important;
                    text-shadow: 0 1px 0 #fff;
                    width: 50px;
                    height: 50px;
                    text-indent: 0;
                    background-image: none !important;
                    position: absolute;
                    top: 20px;
                    right: 20px;
                    opacity: 1;

                }

                .c-speed-bump__media {
                    margin-bottom: 20px;
                    padding-bottom: 20px;
                    border-bottom: 3px solid $red;
                    margin-left: -20px;
                    margin-right: -20px;
                }

                .btn-round {
                    color: $white;
                    border-radius: 20px;
                    font-size: 16px;
                    font-weight: 500;
                    line-height: 24px;
                    padding: 8px 34px;
                    background-color: $red;
                    border-color: $red;

                    &:hover {
                        background-color: $red-alt;
                        border-color: $red-alt;
                    }
                }
            }
        }
    }
}