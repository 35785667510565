
.l-header .c-alert {
    background: -webkit-gradient(linear,left top,left bottom,from(#e11127),to(#c10e21));
    background: linear-gradient(#e11127,#c10e21);
    color: #fff;
    padding: 0;
    display: none;
    height: 0;
    opacity: 0;
    padding: 30px 0;
    
    @include media-breakpoint-down(sm) {
        padding: 10px 0;
        
    }

    &__active {
        display: block;
        height: inherit;
        opacity: 1;
        -webkit-transition: all .5s ease-in 0s;
        transition: all .5s ease-in 0s;

       
    }

    &__container {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: justify;
        -ms-flex-pack: justify;
        justify-content: space-between;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;

        @include media-breakpoint-down(sm) {
            align-items: flex-start;
        }
    }

    &__message {
        display: flex;
    }

    &__kicker {
        
        padding: 10px 20px 10px 0;
        border-right: 1px solid #fff;
        margin-right: 20px;
        font-size: 18px;
        @include media-breakpoint-down(sm) {
            display: none;
        }
    }

    &__content {
        font-size: 18px;
        display: inline-block;
        color: #fff;
    }

    &__cta {
        display: none;
        color: #c10e21;

        &:focus {
            color: $red;
        }
    }



    &__close {
        border-radius: 30px;
        font-size: 22px;
        font-weight: 300;
        line-height: 38px;
        padding: 0;
        width: 40px;
        height: 40px;
        margin-left: 30px;
        
        @include media-breakpoint-down(sm) {
            background: none;
            border: none;
            font-size: 50px;
            line-height: 30px;
        }

        &:active {
            @include media-breakpoint-down(sm) {
                background: none;
                
            }
        }
        

        &:focus {

            @include button-focus-outline;
        }

        &:hover {
            @include media-breakpoint-down(sm) {
                //background: none;
            }
        }
    }
}

.c-alert__content:active, .c-alert__content:focus, .c-alert__content:hover {
    color: #fff;
}

@include media-breakpoint-up(sm) {
    .c-alert__cta, .c-alert__kicker {
        display: inline-block;
    }
}
