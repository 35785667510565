@charset "utf-8";

// Define defaults for each variable.

/* -------------------------------------------------------------------------- */
/*                                 Typography                                 */
/* -------------------------------------------------------------------------- */
// stylelint-disable value-keyword-case
$font-family-sans-serif:      "Montserrat", "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol" !default;
$font-family-monospace:       SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !default;
$font-family-base:            $font-family-sans-serif !default;

$font-size-base:              16px !default; // Assumes the browser default, typically `16px`
$font-size-lg:                ($font-size-base * 1.25) !default;
$font-size-sm:                rem-calc(14) !default;

$font-weight-light:           300 !default;
$font-weight-normal:          400 !default;
$font-weight-bold:            600 !default;

$font-weight-base:            $font-weight-normal !default;
$line-height-base:            1.5 !default;

$h1-font-size:                rem-calc(50, 16) !default;
$h2-font-size:                rem-calc(36, 16) !default;
$h3-font-size:                rem-calc(24, 16) !default;
$h4-font-size:                rem-calc(20, 16)!default;
$h5-font-size:                rem-calc(18, 16) !default;
$h6-font-size:                $font-size-base !default;

/* ---------------------------------- Icons --------------------------------- */
// Icons
$icon-check: "\e900";
$icon-circle-star: "\e903";
$icon-cross: "\e904";
$icon-down-arrow: "\e905";
$icon-heart: "\e906";
$icon-login-signup: "\e907";
$icon-minus: "\e901";
$icon-play: "\e902";
$icon-play-circle: "\e908";
$icon-plus: "\e909";
$icon-print: "\e90a";
$icon-search: "\e90b";
$icon-solid-heart: "\e90c";
$icon-star: "\e90d";
$icon-times: "\e90e";

$box-shadow: 0 15px 30px 0 rgba(0, 0, 0, 0.2);
