*, :after, :before {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}

@include media-breakpoint-up(lg) {
    .container {
        max-width: 1170px;
    }
}

.container {
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
}
